<template>
  <a
    href="https://www.profemina.org/"
    target="_blank"
  >
    <v-img
      alt="Profemina Logo"
      class="shrink mr-2"
      contain
      :src="require('@/assets/logo.png')"
      transition="scale-transition"
      height="50"
    />
  </a>
</template>

<script>
export default {
  name: 'Logo',
};
</script>
